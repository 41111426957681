class MatrizService {
  constructor() {}
  resources = () => ({
    listarPresupuestoReal: {
      uri: `/v1/presupuesto/listar-real-detallado`,
      method: ['get']
    },
    cargarTiposPresupuesto: {
      uri: `/v1/presupuesto/listar-formato`,
      method: ['get']
    },
    exportarPresupuestoReal: {
      uri: `/v1/presupuesto/exportar-presupuesto-real-detallado`,
      method: ['get']
    },
    exportarPresupuestoVSReal: {
      uri: `/v1/presupuesto/exportar-presupuesto-vs-real`,
      method: ['get']
    },
    exportarModeloPresupuestoVSReal: {
      uri: `/v1/presupuesto/descargar-formato-presupuesto-real`,
      method: ['get']
    },
    importarPresupuestoReal: {
      uri: `/v1/presupuesto/importar-real-detallado`,
      method: ['post'],
      headers: { 'Content-Type': 'application/json' }
    }
  });
}

export default MatrizService;
